import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Link from 'gatsby-link';

import * as Styled from './styles';




const Categories = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "categories" } } }
    ) {
      edges {
        node {
          frontmatter {
            name
            caption
            hover
            link
            order
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`);

const categoryData = allMarkdownRemark.edges.reduce((acc,el) => {
    let category = {
        name: el.node.frontmatter.name,
        image: el.node.frontmatter.image,
        hover: el.node.frontmatter.hover,
        link: el.node.frontmatter.link,
        caption: el.node.frontmatter.caption,  
        order: el.node.frontmatter.order      
    }
    acc.push(category);
    return acc;
}, []).sort((a, b) => a.order - b.order);


  return (
        <Styled.Categories>
        <ul className="topic__list">
            {categoryData.map((category, i) => {
                return (
                <div key={`category${i}`} className="topic">
                    <Link to={category.link} activeClassName="active">
                    <figure className="topic__shape">
                        <Img fluid={category.image.childImageSharp.fluid} alt={`Ruth Ng Dillon-Mansfield ${category.name} blogs`} className="topic__img" />
                        <figcaption className="topic__caption">
                        {category.hover}
                        </figcaption>
                        <figcaption className="topic__name">
                        {category.caption}
                        </figcaption>
                    </figure>
                    </Link>
                </div>
                )
            })}
            </ul>
        </Styled.Categories>
  );
};

export default Categories;


    