import React from 'react';
import styled from 'styled-components';

import * as Styled from './styles';

import { FaEgg, FaHatWizard } from 'react-icons/fa';

const Egg = styled(FaEgg)`
  font-size: 3.5rem;
  margin-right: 2rem;
  color: #1e1e1e;

  @media (max-width: 800px) {
    font-size: 4.5rem;
  }
`;

const Wizard = styled(FaHatWizard)`
  font-size: 3.5rem;
  margin-right: 2rem;
  color: #1e1e1e;
  @media (max-width: 800px) {
    font-size: 4.5rem;
  }
`;


const LevelInfo = () => (
  <>
    <Styled.MobInfo>
        <Styled.MobInfoFlex>
          <Egg />
          <p>
            Post assumes a bit of prior knowledge
          </p>
        </Styled.MobInfoFlex>
        <Styled.MobInfoFlex>
          <Wizard />
          <p>
            Post assumes advanced prior knowledge
          </p>
        </Styled.MobInfoFlex>
    </Styled.MobInfo>
    <Styled.LevelInfo>
        <Styled.InfoFlex>
          <Egg />
          <p>
            Post assumes a bit of prior knowledge
          </p>
        </Styled.InfoFlex>
        <Styled.InfoFlex>
          <Wizard />
          <p>
            Post assumes advanced prior knowledge
          </p>
        </Styled.InfoFlex>
    </Styled.LevelInfo>
  </>
);

export default LevelInfo;
