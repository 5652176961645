import styled from 'styled-components';

export const Pagination = styled.section`
    margin: 0 auto;
    margin-top: 5rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;

    @media (max-width: 414px)  {

        p, a {
            font-size: 2.5rem !important; 
        }
        
        .left, .right {
            font-size: 1.5rem;
        }

        .left {
            padding-right: 1.5rem !important;
        }

        .right {
            padding-left: 1.5rem !important;
        }
    }

    .left {
        padding-right: 3rem;
        font-size: 2.5rem;
    }

    .right {
        padding-left: 3rem;
        font-size: 2.5rem;
    }

    p {
        padding: 0 1rem;
        font-family: "Source-Bold", sans-serif;
        color: #c1b9bb;
    }

    a {
        padding: 0 1rem;
        font-family: "Source-Bold", sans-serif;
        font-size: 3rem;
        text-decoration: none;
        color: #4CAF50;

        &.active {
            color: #bd3258;
            font-size: 3.7rem;
        }
    }
`;