import styled from 'styled-components';

export const LevelInfo = styled.div`
    position: fixed;
    right: 0;
    bottom: 13%;
    border-radius: 3px 0 0 3px;
    width: 23rem;
    padding-left: 2rem;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 2;

    @media (max-width: 800px) {
        display: none;
    }
    
    * {
        font-family: 'Source', sans-serif;
        font-size: 1.5rem;
        color: #1e1e1e;
    }
`;

export const InfoFlex = styled.div`
    display: flex;
    align-items: center;
    margin: 1rem;
`;

export const MobInfo = styled.div`
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 800px) {
        display: none;
    }

    * {
        font-family: 'Source', sans-serif;
        font-size: 1.5rem;
        color: #1e1e1e;
    }
`;

export const MobInfoFlex = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    margin: 1rem;
`;