import styled from 'styled-components';

export const Categories = styled.div`

  padding-bottom: 4rem;

  ul {
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
  
  li {
    display: inline-block;
    padding: 2rem 5rem 2rem 5rem;
    margin: 0 0 2rem 2rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    font-size: 1.4rem;
    border-radius: 3px;
    text-align: center;
    transition: all .4s ease .1s;
    border: 3px solid #e5e5e5;
    color: black;
  }

  ul a:first-child li {
    margin-left: 8px;
  }

  li:hover {
    border: 3px solid #797979;
  }

  .active li {
    border: 3px solid #797979 !important;
  }

  /* @media (max-width: 800px)  {
    display: none;  
  } */
  @media (min-width: 800px)  {
    padding: 2.5rem;
  }

  &:before,
  &:after {
    display: block;
    clear: both;
    content: '';
  }


  .topic__list {
    display: flex;
    flex-wrap: wrap;
  }


  .topic {

    font-family: SourceSans;
    display: inline-block;
    margin: 1.5rem;
    border-radius: 50%;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, .3);
    
    &__shape {
        float: left;
        shape-outside: circle(50% at 50% 50%); 
        clip-path: circle(50% at 50% 50%);
        position: relative;
    }

    &__img {
        height: 100%;
        transform: translateX(0rem) scale(1.3);
        backface-visibility: hidden;
        transition: all .3s;
        filter: brightness(90%);
    }

    &__caption {
        position: absolute; 
        font-family: Kosugi;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 20%);
        color: #FFF;
        text-transform: uppercase;
        text-align: center;
        opacity: 0;
        transition: all .5s;
        backface-visibility: hidden;
    }




    @media (max-width: 800px)  {

      margin: 0.5rem;

      &__shape {
        width: 10rem;
        height: 10rem;
      }

      &__caption {
        font-size: 3rem;
      }

      &__name {
        font-size: 1.6rem;
      }
    }
    @media (min-width: 800px)  {

      &__shape {
        width: 20rem;
        height: 20rem;
      }

      &__caption {
        font-size: 5rem;
      }

      &__name {
        font-size: 2.5rem;
      }
    }

    &__name {
      position: absolute; 
      font-family: Kosugi;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -30%);
      color: #FFF;
      text-transform: uppercase;
      text-align: center;
      opacity: 1;
      transition: all .5s;
      backface-visibility: hidden;
    }
  }

  .topic__shape:hover .topic__caption {
      opacity: 1;
      transform: translate(-50%, -50%);
  }

  .topic__shape:hover .topic__name {
    opacity: 0;
    transform: translate(-50%, -200%);
  }

  .topic__shape:hover .topic__img {
      transform: translateX(0rem) scale(1.1);
      filter: blur(5px) brightness(60%);
  }
`;
