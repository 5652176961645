import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styles';

const Pagination = ({children}) => (
  <Styled.Pagination>
  {children}
  </Styled.Pagination>
);

Pagination.propTypes = {
  children: PropTypes.any.isRequired
};
export default Pagination;
