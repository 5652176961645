import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Link from 'gatsby-link';
import { motion } from 'framer-motion';

import styled from 'styled-components';

import { FaTags, FaEgg, FaHatWizard } from 'react-icons/fa';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Hero from 'components/Hero';
import Categories from 'components/Categories';
import Pagination from 'components/ui/Pagination';
import Container from 'components/ui/Container';
import LevelInfo from 'components/ui/LevelInfo';

import * as Styled from './styles';

const Egg = styled(FaEgg)`
  font-size: 40px;
`;

const Wizard = styled(FaHatWizard)`
  font-size: 40px;
`;



const Posts = ({ data, pageContext }) => {
  const { markdownRemark, allMarkdownRemark } = useStaticQuery(graphql`
  query {
    markdownRemark(frontmatter: { category: { eq: "blog section" } }) {
      frontmatter {
        title
        subtitle
        seotitle
        seodescription
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "blog" }, published: { eq: true } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
            description
            topic
            date(formatString: "MMM DD, YYYY")
            tags
            canonical
            level
            cover {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`);


  const tag = pageContext.tag || null;
  const topic = pageContext.topic || null;


  const seotitle = markdownRemark.frontmatter.seotitle;
  const seodescription = markdownRemark.frontmatter.seodescription;
  
  let posts = allMarkdownRemark.edges;
  if (tag) {
    posts = posts.reduce((acc, post) => {
      post.node.frontmatter.tags.map(tag => tag.toLowerCase());
      if (post.node.frontmatter.tags.includes(tag)) {
        acc.push(post);
      }
      return acc;
    }, []);
  }
  if (topic) {
    posts = posts.reduce((acc, post) => {
      if (post.node.frontmatter.tags.includes(topic) || post.node.frontmatter.topic === topic) {
        acc.push(post);
      }
      return acc;
    }, []);
  }
  const subtitle = tag ? tag : topic ? topic : markdownRemark.frontmatter.subtitle;
  const title = markdownRemark.frontmatter.title;
  const page = pageContext.currentPage;
  const limit = pageContext.limit;
  const currentPagePosts = posts.slice((page - 1) * limit, page * limit);
  let numPages = pageContext.numPages;
  if (tag || topic) {
    numPages = Math.ceil(posts.length / limit);
  }
  let prevPage = (<p className='left'>Previous</p>);
  let nextPage = (<p className='right'>Next</p>);
  if (page > 1) {
    if (page > 2) {
      prevPage = <Link to={`/posts/${page - 1}`} activeClassName="active"  className='left'>Previous</Link>;
    } else {
      prevPage = <Link to={`/posts/`} activeClassName="active" className='left'>Previous</Link>;
    }
  }
  if (page < numPages) {
      nextPage = <Link to={`/posts/${page + 1}`} activeClassName="active" className='right'>Next</Link>;
  }


  return (
    <Layout>
      <SEO title={seotitle} description={seodescription} />
      <Hero title={title} subtitle={subtitle}/>
      <Container section pushup="true">
        <Styled.Subhead>
            Sometimes the urge strikes me to write about some rather divergent topics. The result is slightly eclectic blog, so you can use the buttons below to view blogs on particular topics that you're more interested in!
        </Styled.Subhead>
        <Categories />
        <LevelInfo />
        <Styled.Posts>
          {currentPagePosts.map((item) => {
            const {
              id,
              fields: { slug },
              frontmatter: { title, cover, description, date, tags, level, topic }
            } = item.node;
            const levelIcon = level === '1' ? <Egg /> : level === '2' ? <Wizard /> : '';
            return (
              <Styled.Post key={id}>
                <Link to={slug}>
                  <motion.div className='motion' whileHover={{ scale: 1.03 }} whiletap={{ scale: 1 }}>
                    <Styled.Card>
                      <Styled.Category className={topic}>
                        <h5>{topic}</h5>
                        <p>{levelIcon}</p>
                      </Styled.Category>
                      <Styled.Image>
                        <Img fluid={cover.childImageSharp.fluid} alt={title} />
                      </Styled.Image>
                      <Styled.Content>
                        <Styled.Title><span className={topic}>{title}</span></Styled.Title>
                        <Styled.Description><p>{description}</p></Styled.Description>
                        <Styled.Date>{date}</Styled.Date>
                        <Styled.Tags>
                          <FaTags />
                          {tags.map((item) => (
                            <Link key={item} to={`/tags/${item.toLowerCase()}`}>
                              <Styled.Tag >{item}</Styled.Tag>
                            </Link>
                          ))}
                        </Styled.Tags>
                      </Styled.Content>
                    </Styled.Card>
                  </motion.div>
                </Link>
              </Styled.Post>
            );
          })}
        </Styled.Posts>
        { numPages < 2 
          ? <></>
          : <Pagination>
            {prevPage}
            {Array.from({ length: numPages }).map((_, i) => (
              tag 
                ? <Link to={`/tags/${tag.toLowerCase()}/${i=== 0 ? "" : i + 1}`} activeClassName="active">{`${i + 1}`}</Link>
              : topic
                ? <Link to={`/categories/${topic.toLowerCase()}/${i=== 0 ? "" : i + 1}`} activeClassName="active">{`${i + 1}`}</Link> 
                : <Link to={`/posts/${i=== 0 ? "" : i + 1}`} activeClassName="active">{`${i + 1}`}</Link>
            ))}
            {nextPage}
          </Pagination>
        }
          
      </Container>
    </Layout>
  );
};

export default Posts;
